import { debounce } from 'lodash'
import { Controller } from 'stimulus'
import Swiper, { EffectFade } from 'swiper'

Swiper.use([EffectFade])

export default class extends Controller {

  static targets = ['traySlider', 'contentSlider'];

  connect() {
    // initialize the last known user interacted index for the menuTray and menuContent sliders
    this.lastTrayIndex = 0

    this.initMenu()
    window.addEventListener('load', this.updateMenuContentHeight.bind(this))

    // debounce the resize handler to prevent the resize event from firing too frequently
    this.resizeHandler = debounce(this.handleResize.bind(this), 200)
    window.addEventListener('resize', this.resizeHandler)
  }

  initMenu() {
    // Create menuTray Swiper instance
    const menuTraySelector = '.js-menuTray'
    const menuTrayElement = this.element.querySelector(menuTraySelector)
    const menuTraySlidesToShow = parseInt(menuTrayElement.dataset.slidestoshow) || 'auto'

    if (menuTrayElement) {
      this.menuTray = new Swiper(this.traySliderTarget, {
        slidesPerView: 3,
        slidesPerGroup: 1,
        freeMode: true,
        centeredSlides: true,
        threshold: 35,
        resistance: true,
        resistanceRatio: 0.85,
        centeredSlidesBounds: true,
        speed: 150,
        slideActiveClass: 'active',
        allowTouchMove: true,
        breakpoints: {
          700: {
            slidesPerView: menuTraySlidesToShow,
            freeMode: true,
            spaceBetween: 0,
            centeredSlides: true,
            centeredSlidesBounds: true,
            threshold: 70,
          },
          1280: {
            slidesPerView: menuTraySlidesToShow,
            freeMode: true,
            spaceBetween: 0,
            centeredSlides: false,
            allowTouchMove: false,
            threshold: 128,
          }
        }
      })

      this.menuTray.activeIndex = 0
      this.menuTray.updateSlidesClasses()
    }

    // Create menuContent Swiper instance
    const menuContentSelector = '.js-menuContent'
    const menuContentElement = this.element.querySelector(menuContentSelector)

    if (menuContentElement) {
      this.menuContent = new Swiper(this.contentSliderTarget, {
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        autoHeight: true,
        freeMode: false,
        centeredSlides: false,
        threshold: 35,
        resistance: true,
        allowTouchMove: false,
        resistanceRatio: 0.85,
        spaceBetween: 0,
        speed: 150,
        breakpoints: {
          700: {
            threshold: 70,
            centeredSlides: true,
            sliderPerView: 'auto',
          }
        }
      })
    }

    // Make sure both instances exist before linking via their events
    if (this.menuTray && this.menuContent) {
        this.setupEventListeners()
    }
  }

  setupEventListeners() {
    // When the active slide of menuTray changes, update the active slide of menuContent
    this.menuTray.on('slideChange', () => {
      // if the on slideChange the activeIndex is not the last known user interacted index,
      // set it to the last known user interacted index to ensure the menuTray and menuContent sliders are in sync
      // and always match the user's last interaction
      if (this.menuTray.activeIndex !== this.lastTrayIndex) {
        this.menuTray.activeIndex = this.lastTrayIndex
      }
    });

    this.menuTray.on('tap', () => {
      if (this.menuTray.clickedIndex === undefined) return
      // store the last known user interacted indexes for the menuTray slider
      this.lastTrayIndex = this.menuTray.clickedIndex

      this.menuContent.slideTo(this.menuTray.clickedIndex)
      this.menuTray.activeIndex = this.menuTray.clickedIndex
      this.menuTray.updateSlidesClasses()
    });
  }

  /**
   * handle the resize event
   * - if the menuTray slider's activeIndex is not the last known user interacted index,
   * set the menuTray slider's activeIndex to the last known user interacted index
   *  - This is a workaround for the Swiper library recalculating the activeIndex on resize,
   *  if the activeIndex is a slide on the extreme ends of the slider, in an attempt to keep the activeIndex in view
   *  - This is misguided in our implementation, as we want the activeIndex to remain the same,
   *  regardless of the window size, since the user is dictating the activeIndex and we are using the menuTray slider
   *  as a navigation for the menuContent slider.
   *    - This is of particular concern on mobile devices that trigger resize on scroll if the address bar visibility
   *    changes.
   */
  handleResize() {
    // if the menuTray slider's activeIndex is not the last known user interacted index
    if (this.menuTray.activeIndex !== this.lastTrayIndex) {
      // set the menuTray slider's activeIndex to the last known user interacted index,
      // so that the menuTray and menuContent sliders are in sync with the user's last interaction
      this.menuTray.activeIndex = this.lastTrayIndex
      this.menuTray.updateSlidesClasses()
    }

    this.menuTray.slideTo(this.lastTrayIndex, 0, false)
  }

  /**
   * Workaround for autoHeight not working on some edge cases
   * - initial slide has significantly less content than at least one other slide
   * - the height of the largest slide is at least 150% of the viewport height
   * - the page loads with the menu in view, and the above conditions are met
   */
  updateMenuContentHeight() {
    this.menuContent?.updateAutoHeight()
  }
}
